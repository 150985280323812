<template>
    <!-- form -->
    <form class="validate-form" @submit.prevent="createAdminRole">
    <!-- filter questions -->
    <base-card-plain>
        <template #header>
            <h4 class="card-title">Update permissions for various job roles</h4>
        </template>
        <template #default>



                <div class="row" v-if="compIsCreateUpdateRole">
                    <div class="col-12 col-sm-12">
                        <div class="mb-1">
                            <label class="form-label" :class="fv.roleTitle.msg?'text-danger':''" >
                                Role title <span v-if="fv.roleTitle.msg" > | {{ fv.roleTitle.msg }}</span>
                            </label>
                            <input v-model="fv.roleTitle.val"
                                   type="text" class="form-control"
                                   placeholder="Enter the name you'll like to give the role"
                                   @keyup="validate.validateFormData(fv,'roleTitle',true)"
                             />
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="mb-1">
                            <label class="form-label" :class="fv.roleInfo.msg?'text-danger':''" >
                                Role description (optional) <span v-if="fv.roleInfo.msg" > | {{ fv.roleInfo.msg }}</span>
                            </label>
                            <textarea v-model="fv.roleInfo.val"
                                      @keyup="validate.validateFormData(fv,'roleInfo',true)"
                                      class="form-control" rows="4" placeholder="Enter brief description about the role"
                             ></textarea>
                        </div>
                    </div>

                    <div class="col-12 col-lg-12 col-sm-12">
                        <div class="mb-1">
                            <label class="form-label" for="basicSelect">
                                Add role category <span v-if="fv.roleCategory.msg" > | {{ fv.roleCategory.msg }}</span>
                            </label>
                            <select class="form-select" @change="validate.validateFormData(fv,'roleCategory',true)" v-model="fv.roleCategory.val" >
                                <option v-for="cat in roleCategory" :key="cat.value" :value="cat.value">
                                    {{ cat.alias }}
                                </option>
                            </select>
                        </div>

                        <div class="mb-1 role-type" v-for="(role,index) in compRoleList" :key="role.type">
                            <div class="role-type">
                                <b> {{ role.alias }} </b>
                                <span class="float-end cursor-pointer"
                                      @click="selectAllApplicants"
                                      v-if="!index"
                                  >
                                    <u> {{ compActiveUpdate?(isSelectAll?'reset':'select all'):(isSelectAll?'unselect all':'select all') }}  </u>
                                </span>
                            </div>
                            <div class="mb-1">
                                <div class="demo-inline-spacing">
                                    <div class="form-check form-check-secondary" v-for="(opt,index) in role.options" :key="opt+index">
                                        <input type="checkbox"
                                               name="allPermissions[]"
                                               class="form-check-input"
                                               :value="role.type+'_'+opt"
                                               :checked="isSelectAll?'checked':validateActivePermission(opt+'_'+role.type)"
                                         />
                                        <label class="form-check-label" for="colorCheck2">{{ opt }} {{ role.type.replace("_"," ") }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="col-12" v-if="fv.reqFeedback.msg">

                    <base-list-tag :tagColor="fv.reqFeedback.val" >{{fv.reqFeedback.msg}}</base-list-tag>
                </div>

                <div class="col-12"  v-if="fv.showSubmit.val">
                    <base-button btnColor="primary" >
                         {{ currentRoleAction === "create"?"Create role":`${currentRoleAction} role` }}
                    </base-button>
                </div>
                <base-spinner  v-if="fv.showLoader.val"></base-spinner>



        </template>
    </base-card-plain>
    <!-- / filter questions -->






    </form>
    <!--/ form -->
</template>

<script>
import { ref,reactive,computed,inject } from 'vue';
import validators from '@/hooks/validation';
export default{
    emits:['emitUpdateExistingRolesList'],
    props:{
        currentRoleAction:{
            type:String,
            required:true,
            default:"empty"
        },
        currentRoleActionData:{
            type:Object,
            default:()=>{}
        }
    },
    setup( props , { emit } ){

        // set the validators function
        const validate = validators();

        // get the axios object
        const axios = inject('axios');  // inject axios

        //get the urls
        const coreUrl = inject('coreUrl');

        const roleList = ref([
            {category:["manager","recruiter"],type:"recruitment",alias:"Recruitment Permissions",options:["create","view","delete"]},
            {category:["manager","recruiter"],type:"tests",alias:"Tests Permissions",options:["create","view","delete"]},
            {category:["manager","recruiter","analyst"],type:"reports",alias:"Reports Permissions ( dashboard, analytics, activity log ) ",options:["view"]},
            {category:["manager"],type:"team",alias:"Team Permissions",options:["create","view","delete"]},
            {category:["manager"],type:"account_profile",alias:"Account Profile Permissions",options:["create","view"]},
            {category:["manager"],type:"settings",alias:"Settings Permissions ( preferences ) ",options:["create","view"]}
        ])

        const roleCategory = ref([
            {alias:"Select",value:""},
            {alias:"Manager",value:"manager"},
            {alias:"Recruiter",value:"recruiter"},
            {alias:"Analyst",value:"analyst"}
        ])

        const compRoleList = computed(function(){

            return roleList.value.filter((list) => list.category.includes(fv.roleCategory.val));
        })

        const compActiveUpdate = computed(function(){
            return (props.currentRoleAction === "update" && Object.keys(props.currentRoleActionData).length > 0) ? true : false;
        })

        const compActiveUpdateDelete = computed(function(){
            return (props.currentRoleAction === "update" || props.currentRoleAction === "delete") ? true : false;
        })

        const isSelectAll = ref(false)

        function selectAllApplicants(){
            isSelectAll.value=!isSelectAll.value
        }

        function validateActivePermission(permission){
            if(props.currentRoleAction === "update"){
                if(JSON.parse(props.currentRoleActionData.roles_permissions).includes(permission)){
                    return 'checked';
                }
            }

            return null;
        }

        const fv = reactive({
            roleTitle : {status:false, val:compActiveUpdate.value?props.currentRoleActionData.role_title:"", msg:false, validate:["required",
                                                                    "safeSpaceInput",
                                                                    {option:"textMin",val:3},
                                                                    {option:"textMax",val:120}]},
            roleInfo : {status:false, val:compActiveUpdate.value?props.currentRoleActionData.roles_description:"", msg:false, validate:["required",
                                                                    {option:"textMin",val:10},
                                                                    {option:"textMax",val:500}
                                                                ]},
            roleCategory: {status:false, val:compActiveUpdate.value?props.currentRoleActionData.role_category:"", msg:false, validate:["required"]},
            rolePermissions : {status:false, val:compActiveUpdate.value?JSON.parse(props.currentRoleActionData.roles_permissions):[], msg:false, validate:["requiredArray"]},
            roleExistingId: {status:false, val:compActiveUpdateDelete.value?props.currentRoleActionData.id:"", msg:false},
            reqFeedback : {status:false, val:"", msg:false},
            showSubmit : {status:false, val:true},
            showLoader : {status:false, val:false}
        });


        const compIsCreateUpdateRole = computed(function(){
            return props.currentRoleAction === "create" || props.currentRoleAction === "update" ? true : false;
        })


        async function createAdminRole(){
            let allVal = document.querySelectorAll('input[name="allPermissions[]"]:checked');

            fv.rolePermissions.val = props.currentRoleAction === "create"?[]:fv.rolePermissions.val;

            for (var i = 0; i < allVal.length; i++) {
              fv.rolePermissions.val.push(allVal[i].value);
            }

            fv.showSubmit.val = false;
            fv.showLoader.val = true;

            // load the form data , and append all the necessary fields
            let formData = new FormData();

            if(compIsCreateUpdateRole.value){
                formData.append("fv_roleTitle", fv.roleTitle.val);
                formData.append("fv_roleInfo", fv.roleInfo.val);
                formData.append("fv_roleCategory", fv.roleCategory.val);
                formData.append("fv_rolePermissions", fv.rolePermissions.val.length > 0?JSON.stringify(fv.rolePermissions.val):"");
            }

            if(compActiveUpdateDelete.value){
                formData.append("fv_roleExistingId", fv.roleExistingId.val);
            }

            const actionLinks = {
                create:'create_admin_role',
                update:'update_admin_role',
                delete:'delete_admin_role'
            }


            try{
                const response = await axios.post(
                    coreUrl.backendUrl+actionLinks[props.currentRoleAction],
                    formData,
                    {
                        withCredentials:true,
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    }
                )

                const responseData = response.data;

                if(responseData.status){
                    fv.reqFeedback.status = true;
                    fv.reqFeedback.msg = responseData.message.req_msg;
                    fv.reqFeedback.val = "success";
                    fv.showLoader.val = false;


                    emit( 'emitUpdateExistingRolesList' , responseData.data, props.currentRoleAction )
                }else{
                    // load the error to the appropriate field
                    const fv_msg = responseData.message.fv_msg;
                    const req_msg = responseData.message.req_msg;

                    for(const key in fv_msg){
                        if(fv[key]){
                            fv[key]["status"] = false;
                            fv[key]["msg"] = fv_msg[key];
                        }
                    }


                    fv.reqFeedback.status = true;
                    fv.reqFeedback.msg = req_msg;
                    fv.reqFeedback.val = "danger";

                    // end the action of making a submission, and hide loader
                    fv.showSubmit.status = true;
                    fv.showLoader.val = false;
                }
            }catch(error){
                console.log(error)
                fv.showSubmit.status = true;
                fv.showLoader.val = false;
            }


        }


        return {
            fv,
            validate,
            roleList,
            roleCategory,
            compRoleList,
            createAdminRole,
            isSelectAll,
            compActiveUpdate,
            validateActivePermission,
            selectAllApplicants,
            compIsCreateUpdateRole
        }
    }
}


</script>

<style scoped>
.role-type{
    background-color:#f5f5f5;
    border-radius:5px;
    width:100%;
    padding:10px;
}
</style>
