<template>


    <!-- BEGIN: Content-->
    <div class="content-body">
        <!-- full job description -->
        <!-- Bordered table start -->
        <div class="row" >
            <div class="col-12">
                <div v-if="isRolesRequest">
                    <base-card-plain :showFooter="false" >
                        <template #header>

                            <div class="col-12 col-md-12 col-lg-12 col-sm-12 mb-1">
                                <div class="input-group">
                                    <input type="text" class="form-control"
                                           placeholder="Search using role title, category or permissions "
                                           aria-describedby="button-addon2"
                                           v-model="roleSearch"
                                    />
                                </div>
                            </div>
                        </template>
                        <template #default>

                            <div>
                                <b>
                                    {{ compTeamRoleTitle }}
                                </b>
                                <b class="float-end cursor-pointer"
                                   data-bs-toggle="modal"
                                   data-bs-target="#actionRolesModal"
                                   @click="loadCurrentRoleAction('create', {id:'newRoleAction'})"
                                 >
                                    <i class="bi bi-plus"></i>
                                     Create new role
                                 </b>
                            </div>

                            <base-card-response cardType="beige"
                                                cardColor="text-black"
                                                class="mt-1" v-for="(role,index) in compCurrentRolesList"
                                                :key="role.id">

                                <template #header>
                                    <span class="card-title">
                                        {{ role.role_title }} ( {{ role.role_category }})
                                    </span>
                                    <span class="float-end">
                                        <base-button btnType="outline"
                                                     btnColor="secondary"
                                                     class="me-1"
                                                     data-bs-toggle="modal"
                                                     data-bs-target="#actionRolesModal"
                                                     @click="loadCurrentRoleAction('update', role )"
                                        >
                                            <i class="bi bi-pencil-square"></i> Update
                                        </base-button>
                                        <base-button  btnColor="secondary"
                                                      data-bs-toggle="modal"
                                                      data-bs-target="#actionRolesModal"
                                                      @click="loadCurrentRoleAction('delete', role)"
                                        >
                                            <i class="bi bi-trash"></i> Delete
                                        </base-button>
                                    </span>
                                </template>

                                <template #default>
                                    <div v-if="role.overflow">
                                        <p>
                                            {{ role.roles_description }}
                                        </p>
                                        <p>
                                            <base-badge badgeColor="secondary" class="mb-1"
                                                        v-for="(per,index) in JSON.parse(role.roles_permissions)"
                                                        :key="per+index">
                                                    {{ per }}
                                            </base-badge>
                                        </p>
                                    </div>
                                    <p class="cursor-pointer"
                                        @click="compCurrentRolesList[index]['overflow'] = !compCurrentRolesList[index]['overflow']">
                                        <u>{{ compCurrentRolesList[index]['overflow']?'hide':'show' }} details </u>
                                    </p>
                                </template>

                            </base-card-response>

                        </template>
                    </base-card-plain>
                </div>
                <base-card-response cardType="secondary" class="mt-1" v-else>

                    <template #header> Team roles for your organization </template>

                    <template #default>
                        <p>
                            You currently don't have any custom roles.
                        </p>
                    </template>

                </base-card-response>
            </div>
        </div>
        <!-- Bordered table end -->
        <!-- full job description -->



    </div>
    <!-- END: Content-->

    <base-modal mId="actionRolesModal"  mBg="bg-bage" mSize="lg"  >
         <template #header-left-slot>
            {{ currentRoleActionTitle }}
         </template>

         <template #default>

             <!-- create admin roles  -->
             <create-admin-roles
                 :currentRoleAction="currentRoleAction"
                 :currentRoleActionData="currentRoleActionData"
                 @emit-update-existing-roles-list="updateExistingRolesList"
                 :key="currentRoleActionKey"
             >
             </create-admin-roles>
             <!--/ create admin roles -->

         </template>
    </base-modal>


</template>

<script>
import { ref, toRefs,computed } from 'vue';
import CreateAdminRoles from '@/components/forms/admin/CreateAdminRoles.vue';

export default {
    props: {
        existingRolesList :{
            type:Array,
            default:()=>[]
        },
        isRolesRequest:{
            type:Boolean,
            default:true
        }
    },
    components:{
        CreateAdminRoles
    },
    setup(props){

        const { existingRolesList: currentRolesList } = toRefs(props);

        const roleSearch = ref("");

        const compCurrentRolesList = computed(function(){
            let finalData = []

            finalData = currentRolesList.value.filter(function(item){
                    if(
                        ( roleSearch.value?item.role_title_key.toLowerCase().replace(" ","_").search(roleSearch.value) >= 0 : true ) ||
                        ( roleSearch.value?item.role_category.toLowerCase().replace(" ","_").search(roleSearch.value) >= 0 : true ) ||
                        ( roleSearch.value?JSON.stringify(item.roles_permissions).toLowerCase().replace(" ","_").search(roleSearch.value) >= 0 : true )
                      ){

                        return true;
                    }
                    return false
            })


            return finalData;
        })

        const compTeamRoleTitle = computed(function(){
            return roleSearch.value == ""?
                    `${compCurrentRolesList.value.length} Team roles exists in your  organization`:
                    `${compCurrentRolesList.value.length} "${roleSearch.value}" roles exists in your organization`
        })

        function updateExistingRolesList(data, action){
            if(action === "create"){
                currentRolesList.value.push(data)
            }

            if(action === "update"){
                let roleIndex = currentRolesList.value.map(object => object.id).indexOf(data.id);
                currentRolesList.value[roleIndex] = data
            }

            if(action === "delete"){
                let roleIndex = currentRolesList.value.map(object => object.id).indexOf(data);
                currentRolesList.value.splice(roleIndex, 1)
            }

        }

        const currentRoleAction = ref("");
        const currentRoleActionKey = ref(false);
        const currentRoleActionData = ref({});
        const currentRoleActionTitle = ref("");

        function loadCurrentRoleAction(action, data){

            currentRoleAction.value = action;
            currentRoleActionKey.value = data.id;
            currentRoleActionData.value = data;
            currentRoleActionTitle.value = `${action.charAt(0).toUpperCase() + action.slice(1)} ${action == 'create'?' new role':data.role_title}`;

        }


        return {
            compCurrentRolesList,
            compTeamRoleTitle,
            roleSearch,
            updateExistingRolesList,
            loadCurrentRoleAction,
            currentRoleAction,
            currentRoleActionData,
            currentRoleActionKey,
            currentRoleActionTitle
        }
    }
}
</script>

<style scoped>
.multi-icon-spacer{
    padding:2px;
}
</style>
