<template>


    <!-- BEGIN: Content-->
    <div class="app-content content ">
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper container-xxl p-0">
            <div class="content-header row">
                <div class="content-header-left col-md-9 col-12 mb-2">
                    <div class="row breadcrumbs-top">
                        <div class="col-12">
                            <h2 class="content-header-title float-start mb-0">Manage Roles</h2>
                            <div class="breadcrumb-wrapper">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="#">Create a new role</a>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-body">
                <!-- account setting page -->
                <section id="page-account-settings">
                    <div class="row">
                        <!-- left menu section -->
                        <div class="col-md-3 mb-2 mb-md-0">
                            <ul class="nav nav-pills flex-column nav-left">
                                <!-- Job description -->
                                <li class="nav-item">
                                    <a class="nav-link active" id="post-job-pill-jd" data-bs-toggle="pill" href="#post-job-jd" aria-expanded="true">
                                        <i class="bi bi-shield-lock bs-icon-btn-tiny font-medium-3 mb-1 me-2" ></i>
                                        <span class="fw-bold">Create new role</span>
                                        <span class="bg-secondary rounded-circle text-white float-end minor-pad ms-2"  >{{ compExistingTeamList.length }}</span>
                                    </a>
                                </li>
                                <!-- Options -->
                                <li class="nav-item">
                                    <a class="nav-link" id="post-job-pill-options" data-bs-toggle="pill" href="#post-job-options" aria-expanded="false">
                                        <i class="bi bi-card-list bs-icon-btn-tiny font-medium-3 mb-1 me-2" ></i>
                                        <span class="fw-bold float-end">
                                            Roles list
                                        </span>
                                        <span class="bg-secondary rounded-circle text-white float-end minor-pad ms-2"  >{{ compExistingRolesList.length }}</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <!--/ left menu section -->

                        <!-- right content section -->
                        <div class="col-md-9">
                            <div class="card">
                                <div class="card-body">
                                    <div class="tab-content">
                                        <!-- post-job-pill-jd -->
                                        <div role="tabpanel" class="tab-pane active" id="post-job-jd" aria-labelledby="post-job-pill-jd" aria-expanded="true">



                                            <!-- create admin roles  -->
                                            <admin-team-list
                                                :existingTeamList="compExistingTeamList"
                                                :existingRolesList="compExistingRolesList"
                                                :isTeamRequest="isTeamRequest"
                                                v-if="loadExistingTeam"
                                            >
                                            </admin-team-list>
                                            <!--/ create admin roles -->



                                        </div>
                                        <!--/ post-job-pill-jd -->

                                        <!-- post-job-pill-option -->
                                        <div class="tab-pane fade" id="post-job-options" role="tabpanel" aria-labelledby="post-job-pill-options" aria-expanded="false">

                                            <!-- admin roles list -->
                                            <admin-roles-list
                                                :existingRolesList="compExistingRolesList"
                                                :isRolesRequest="isRolesRequest"
                                                v-if="loadExistingRoles"
                                            >
                                            </admin-roles-list>
                                            <!-- / admin roles list -->

                                        </div>
                                        <!--/ post-job-pill-option -->


                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--/ right content section -->
                    </div>
                </section>
                <!-- / account setting page -->

            </div>
        </div>
    </div>
    <!-- END: Content-->


</template>

<script>
// import the core functions from vuex
import { ref,computed,inject } from 'vue';
import { useStore } from 'vuex';


import AdminRolesList from '@/components/admin/AdminRolesList.vue';
import AdminTeamList from '@/components/admin/AdminTeamList.vue';

export default{
    components:{
        AdminRolesList,
        AdminTeamList
    },
    setup(){
        // get the global vuex store values
        const store = useStore();

        // get the axios object
        const axios = inject('axios');  // inject axios

        //get the urls
        const coreUrl = inject('coreUrl');

        // use the dispatch function to call an action that calls a mutation
        store.dispatch("globals/executeSetNavMenu",true);

        const existingTeamList = ref([])
        const loadExistingTeam = ref(false)
        const isTeamRequest = ref(true)

        const existingRolesList = ref([])
        const loadExistingRoles = ref(false)
        const isRolesRequest = ref(true)

        axios.get(coreUrl.backendUrl+'get_org_user_team_and_roles',
        {withCredentials: true}).then((response)=>{

            if(response.data.status){
                console.log(response.data.data.roles)
                existingTeamList.value = response.data.data.team?response.data.data.team:[];
                loadExistingTeam.value = true;

                existingRolesList.value = response.data.data.roles?response.data.data.roles:[];
                loadExistingRoles.value = true;
            }else{
                existingTeamList.value = [];
                loadExistingTeam.value = true;

                existingRolesList.value = [];
                loadExistingRoles.value = true;
            }


        }).catch((error)=>{
            console.log(error)
            isTeamRequest.value = false;
            loadExistingTeam.value = true;

            isRolesRequest.value = false;
            loadExistingRoles.value = true;
        })

        const compExistingTeamList = computed( function(){

            // let rolesList = existingRolesList.value.map(v => ({...v, overflow: false}));
            let teamList = existingTeamList.value;

            let sortedList =  teamList.sort((teamList,b)=> (teamList.full_name > b.full_name ? 1 : -1));

            return sortedList

        })

        const compExistingRolesList = computed( function(){

            // let rolesList = existingRolesList.value.map(v => ({...v, overflow: false}));
            let rolesList = existingRolesList.value;

            let sortedList =  rolesList.sort((rolesList,b)=> (rolesList.role_title > b.role_title ? 1 : -1));

            return sortedList

        })


        return {
            compExistingRolesList,
            loadExistingRoles,
            isRolesRequest,
            compExistingTeamList,
            loadExistingTeam,
            isTeamRequest
        }
    }
}


</script>

<style scoped>
.bs-icon-small{
    height: 1.5rem;
    width: 1.5rem;
    font-size: 1.5rem;
}

.bs-icon-tiny{
    height: 0.5rem;
    width: 0.5rem;
    font-size: 0.5rem;
    margin-right: 1.1rem;
    flex-shrink: 0;}


.minor-pad{
    padding:5px;
}
</style>
